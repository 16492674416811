.scrollContainer {
  border-right: 24px solid rgba(0, 0, 0, 0);
}

@screen sm {
  .scrollContainer {
    @apply pr-6;
    border-right: none;
  }
}

.outer {
  @apply absolute;
  @apply inset-0;
  @apply top-auto;
}

.inner {
  max-height: 90vh;
  min-height: 40vh;
}

@screen sm {
  .outer {
    @apply max-w-screen-sm;
    @apply right-auto;
    @apply bottom-auto;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .inner {
    min-height: auto;
  }
}

@keyframes rotate {
  4.5% {
    transform: rotate(32.7deg);
  }
  9% {
    transform: rotate(32.7deg);
  }

  13.5% {
    transform: rotate(65.4deg);
  }
  18% {
    transform: rotate(65.4deg);
  }

  22.5% {
    transform: rotate(98.1deg);
  }
  27% {
    transform: rotate(98.1deg);
  }

  31.5% {
    transform: rotate(130.8deg);
  }
  36% {
    transform: rotate(130.8deg);
  }

  40.5% {
    transform: rotate(163.5deg);
  }
  45% {
    transform: rotate(163.5deg);
  }

  49.5% {
    transform: rotate(196.2deg);
  }

  54% {
    transform: rotate(196.2deg);
  }

  58.5% {
    transform: rotate(228.9deg);
  }

  63% {
    transform: rotate(228.9deg);
  }

  67.5% {
    transform: rotate(261.6deg);
  }

  72% {
    transform: rotate(261.6deg);
  }

  76.5% {
    transform: rotate(294.3deg);
  }

  81% {
    transform: rotate(294.3deg);
  }

  85.5% {
    transform: rotate(327deg);
  }

  90% {
    transform: rotate(327deg);
  }

  95% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.film {
  @apply absolute;
  top: -28.7%;
  left: 50%;
  margin-left: -36%;
  width: 72%;
  animation: rotate 15s linear infinite;
}

.viewmaster {
}
